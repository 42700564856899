<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} tipo de empleado`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="employeeType.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de tipos de empleado"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeEmployeeTypes"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue')
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      employeeType: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      employeeTypes: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    activeEmployeeTypes() {
      return this.employeeTypes.filter(category => category.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.employeeType)
    }
  },
  async created() {
    this.employeeType.id_autor = this.userData.id
    this.employeeType.id_institucion_educativa = this.institutionId
    this.employeeTypes = await this.fetchEmployeeTypeByEI(this.institutionId)

  },
  methods: {
    async fetchEmployeeTypeByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/tipo-empleado-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener tipos de empleado por institucion educativa', error);
      }
    },
    async createEmployeeType() {
      try {
        const response = await api.post('/personas/crear-tipo-empleado', {
          tipo_empleado: this.employeeType.dato,
          id_institucion_educativa: this.employeeType.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear el tipo de empleado.')
        }
      } catch (error) {
        console.error('Error al intentar crear tipo de empleado', error)
        this.errors.push('Error al intentar crear tipo de empleado')
      }
    },
    async updateEmployeeType() {
      try {
        const response = await api.patch('/personas/update-tipo-empleado', {
          id: this.employeeType.id,
          tipo_empledo: this.employeeType.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar el tipo de empleado')
        }
      } catch (error) {
        console.error('Error al intentar actualizar tipo de empleado', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteEmployeeType(typeId) {
      try {
        const response = await api.post('/personas/delete-tipo-empleado', {
          id_tipo_empleado: typeId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar el tipo de empleado')
        }
      } catch (error) {
        console.error('Error al intentar eliminar tipo de empleado', error);
      }
    },
    async save() {
      await this.createEmployeeType()
      this.employeeTypes = await this.fetchEmployeeTypeByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.employeeType = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.employeeType = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.employeeType = {...item}
      await this.updateEmployeeType()
      this.employeeTypes = await this.fetchEmployeeTypeByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteEmployeeType(itemId)
      this.employeeTypes = await this.fetchEmployeeTypeByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>